import React, { useContext } from 'react'
import _ from 'lodash'
import { Col, Row, Tag, Progress } from 'antd'
import moment from 'moment';

import { UnlockOutlined, LockOutlined } from '@ant-design/icons'
import { AulaCard, BoxPercent } from './styles';
// context
import { CourseContext } from '../../ClassContent/CourseDetail.container'
import { IClass } from '../../../../shared/Models/IClass';
import gtm from '../../../../shared/gtm';

type Props = {
  classItem: IClass;
  locked: 1 | 0;
  order: number;
};

function ClassCard({ classItem, locked, order }: Props) {
  const { selectedClass, setSelectedClass, setClassStep, course } = useContext(
    CourseContext
  ) as any;

  function handleSelectClass(classItem: IClass) {
    setClassStep('intro');
    setSelectedClass(classItem);
    const dataLocal = JSON.parse(localStorage.getItem('persist:root') || '{}');
    const user = JSON.parse(dataLocal?.auth || '{}')?.user;
    const dataGtm = {
      event: 'class_started',
      user_id: user?.id || '', // colocar o mesmo user_id usado dentro da plataforma
      clientFirstName: user?.firstName || '', // Primeiro Nome do cliente
      clientLastName: user?.lastName || '', // Último nome do cliente
      clientemail: user?.email || '', //e-mail utilizado (hash)
      clientphone: user?.phoneNumber || '', // telefone utilizado (hash)
      course_name: course?.title || '', //nome do curso que o usuário esta cursando
      course_category: course?.course_category || '',
      cid: course?.id || '', // id do curso
      className: classItem?.titulo || '', //nome da aula que o usuário está vendo
      fid: course.id_course_category, // id da família
    };
    const path = window.location.pathname.split('/')[1];
    gtm(path, dataGtm);
  }

  const renderClassDayInterval = (classItem: IClass) => {
    const days = _.get(classItem, 'days', []);
    if (days.length > 0) {
      const momentDateCard = moment(days[0].date, 'DD/MM/YYYY')
        .format('dddd, D [de] MMMM')
        .replace('-feira', '');
      const formatDateCard = momentDateCard.charAt(0).toUpperCase() + momentDateCard.slice(1);
      return (
        <div>
          <span>{formatDateCard}</span>
        </div>
      );
    } else {
      return '';
    }
  };
  const { id, titulo, video_played, downloaded_pdf, first_test, class_slug } = classItem;

  let percent = 0;
  const existVideo =
    Object.keys(classItem).find((element) => element === 'aula_videos') && classItem.video_embed
      ? classItem.video_embed
      : classItem.aula_videos?.length > 0
      ? classItem.aula_videos[0].video_embed
      : '';
  const existPDF = classItem.pdf_file;
  const existTest = classItem.have_lecture;

  let stepsCt = 0;
  stepsCt += existPDF ? 1 : 0;
  if (existVideo) {
    if (classItem.aula_videos?.length) {
      stepsCt += classItem.aula_videos?.length;
    } else if (classItem.video_embed) {
      stepsCt += 1;
    }
  }
  stepsCt += existTest ? 1 : 0;
  const completionPercentage = 100 / stepsCt;
  if (video_played) {
    percent += completionPercentage * video_played;
  }

  //
  // percantage for downloaded_pdf
  if (downloaded_pdf) {
    percent += completionPercentage;
  }
  //
  // percantage for first_test
  if (first_test) {
    percent += completionPercentage;
  }

  if (percent >= 99) {
    percent = 100;
  }
  return (
    <AulaCard
      className={`lessons-item${selectedClass && id === selectedClass.id ? ' current' : ''} ${
        locked === 1 ? 'locked' : 'unlocked'
      }`}
      onClick={() => (locked === 1 ? setSelectedClass('locked') : handleSelectClass(classItem))}
    >
      <Row className="colIconDateTitle">
        <Col>
          <span className="block-class">
            {locked === 1 ? <LockOutlined /> : <UnlockOutlined />}
          </span>
        </Col>
        <Col xs={24}>
          <span>
            <p className="data-class">{renderClassDayInterval(classItem)}</p>
            <p className="title-class">{titulo}</p>
          </span>
        </Col>
      </Row>
      <Col xs={24}>
        <BoxPercent>
          <span className="tag-style-class">Aula {order}</span>
          {class_slug === 'simulado' && <Tag className="tag-simulado">Simulado</Tag>}
          {class_slug === 'simuladocomvideo' && (
            <Tag className="tag-simulado">Simulado com video</Tag>
          )}
          <span className="tag-percent">
            {Math.ceil(percent)}
            {'%'}
          </span>
        </BoxPercent>
        <Col className="colBase" xs={24}>
          <Progress
            strokeWidth={5}
            percent={percent}
            showInfo={false}
            strokeColor={'#F89C3A'}
            trailColor={'#EFEFEF'}
          />
        </Col>
      </Col>
    </AulaCard>
  );
}

  export default ClassCard;


