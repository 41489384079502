import React, { useEffect } from 'react';
import { node, string } from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import * as authSelectors from '../redux/selectors/auth';
import request from '../shared/request';
import { useNotifications } from 'contexts/notifications';

const propTypes = {
  component: node,
  authToken: string,
};

const ProtectedRoute = ({ component: Component, ...props }) => {
  const { getNotifications } = useNotifications();
  useEffect(() => {
    const checkAuthentication = async () => {
      const { authToken } = props;
      if (authToken)
        request(`/v1/check-token-expiration`, {
          method: 'GET',
        });
    };

    checkAuthentication();

    const intervalId = setInterval(checkAuthentication, 1000 * 60 * 10);
    getNotifications(1);
    return () => clearInterval(intervalId);
    //eslint-disable-next-line
  }, []);

  return (
    <Route
      {...props}
      render={(routeProps) => {
        const { authToken } = props;

        if (authToken) {
          return <Component {...routeProps} />;
        }

        // Conditions not met, sendign to login page
        return (
          <Redirect
            to={{
              pathname: '/auth/login',
              state: { referrer: routeProps.location },
            }}
          />
        );
      }}
    />
  );
};

const mapStateToProps = (state, props) => ({
  authToken: authSelectors.getAuthToken(state, props),
  customer: authSelectors.getCustomer(state, props),
});

ProtectedRoute.propTypes = propTypes;
export default connect(mapStateToProps)(ProtectedRoute);
